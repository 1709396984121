import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-9" }
const _hoisted_2 = { class: "col-xl-9" }
const _hoisted_3 = {
  key: 0,
  class: "card mb-5 mb-xl-8 min-h-100"
}
const _hoisted_4 = {
  class: "infinite-list",
  style: {"overflow":"auto"}
}
const _hoisted_5 = { class: "card mb-5 mb-xl-8" }
const _hoisted_6 = { class: "card-body pb-0" }
const _hoisted_7 = { class: "d-flex align-items-center mb-5" }
const _hoisted_8 = { class: "d-flex align-items-center flex-grow-1" }
const _hoisted_9 = { class: "symbol symbol-45px me-5 ms-8" }
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = { class: "d-flex flex-column" }
const _hoisted_12 = {
  href: "#",
  class: "text-gray-800 text-hover-primary fs-6 fw-bolder"
}
const _hoisted_13 = { class: "text-gray-400 fw-bold" }
const _hoisted_14 = { class: "card-toolbar" }
const _hoisted_15 = { class: "my-0" }
const _hoisted_16 = { class: "mb-7" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 0,
  class: "d-flex flex-row bd-highlight mb-3"
}
const _hoisted_19 = { class: "col-xl-3" }
const _hoisted_20 = { class: "card mb-5 mb-xxl-8" }
const _hoisted_21 = { class: "card-body pt-9 pb-0" }
const _hoisted_22 = { class: "d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_23 = { class: "d-flex flex-wrap flex-stack" }
const _hoisted_24 = { class: "d-flex flex-column flex-grow-1" }
const _hoisted_25 = { class: "d-flex flex-wrap" }
const _hoisted_26 = { class: "border border-gray-300 border-dashed rounded min-w-225px py-2 mb-3" }
const _hoisted_27 = { class: "d-flex align-items-center me-5" }
const _hoisted_28 = { class: "svg-icon svg-icon-3 svg-icon-danger me-2" }
const _hoisted_29 = {
  class: "fs-2 fw-bolder",
  "data-kt-countup": "true",
  "data-kt-countup-value": "4500",
  "data-kt-countup-prefix": "$"
}
const _hoisted_30 = { class: "fw-bold fs-6 text-gray-400 me-5" }
const _hoisted_31 = { class: "border border-gray-300 border-dashed rounded min-w-225px py-2 mb-3" }
const _hoisted_32 = { class: "d-flex align-items-center me-5" }
const _hoisted_33 = { class: "svg-icon svg-icon-3 svg-icon-danger me-2" }
const _hoisted_34 = { class: "svg-icon svg-icon-3 svg-icon-success me-2" }
const _hoisted_35 = {
  class: "fs-2 fw-bolder",
  "data-kt-countup": "true",
  "data-kt-countup-value": "75"
}
const _hoisted_36 = { class: "fw-bold fs-6 text-gray-400 me-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.actualitiesData.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_el_empty, {
              description: _ctx.t('news.noNews')
            }, null, 8, ["description"])
          ]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actualitiesData, (item, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("img", {
                        src: _ctx.store.getters.serverConfigUrl.building.logo,
                        alt: _ctx.store.getters.serverConfigUrl.building.name
                      }, null, 8, _hoisted_10)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("a", _hoisted_12, _toDisplayString(_ctx.actualitiesData[index].title), 1),
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.moment(_ctx.actualitiesData[index].createdAt).fromNow()), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("span", {
                      class: _normalizeClass([`text-primary`, "symbol-label fs-5 fw-bolder"])
                    }, _toDisplayString(_ctx.actualitiesData[index].category), 1)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_Dropdown3)
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", {
                    class: "text-gray-800 mb-5",
                    innerHTML: _ctx.actualitiesData[index].desc
                  }, null, 8, _hoisted_17),
                  (_ctx.actualitiesData[index].photos.length != 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actualitiesData[index].photos, (photo) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "p-2 bd-highlight",
                            key: photo
                          }, [
                            _createVNode(_component_el_image, {
                              style: {"width":"150px","height":"150px"},
                              src: photo,
                              "preview-src-list": _ctx.actualitiesData[index].photos,
                              "initial-index": 0,
                              fit: "cover"
                            }, null, 8, ["src", "preview-src-list"])
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]))
        }), 128))
      ], 512), [
        [_directive_infinite_scroll, _ctx.load]
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("span", _hoisted_28, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr065.svg" })
                      ]),
                      _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.totalNewPosts), 1)
                    ]),
                    _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.t("news.newPosts")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("span", _hoisted_33, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr065.svg" })
                      ]),
                      _createElementVNode("span", _hoisted_34, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr066.svg" })
                      ]),
                      _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.actualitiesData.length), 1)
                    ]),
                    _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.t("news.totalPosts")), 1)
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}