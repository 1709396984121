
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { date } from "yup/lib/locale";
import { useStore } from "vuex";
const store = useStore();

export default defineComponent({
  name: "Actualités",
  components: {},
  setup() {
    const store = useStore();

    const count = ref(0);
    const load = () => {
      count.value += 2;
    };
    const studentID = window.localStorage.getItem("activeStudent");

    const { t } = useI18n();
    const actualitiesData = ref<any>([]);
    const totalNewPosts = ref(0);
    const apiUrl = store.getters.serverConfigUrl.base_url + "/";
    ApiService.post("/actuality/filter", {
      query: {},
      aggregation: [
        { $match: { target: { $in: ["all", "parents"] } } },
        { $sort: { createdAt: -1 } },
      ],
    })
      .then(({ data }) => {
        let filterdData: any = [];
        data.forEach((element) => {
          let photos = element.photos.map((photo) => {
            return apiUrl + photo;
          });
          element.photos = photos;
          filterdData.push(element);
        });
        actualitiesData.value = filterdData;
      })
      .catch((e) => console.log(e));
    ApiService.get(`/actuality/parents/${studentID}`)
      .then(({ data }) => {
        data.forEach((element) => {
          let photos = element.photos.map((photo) => {
            return apiUrl + photo;
          });
          element.photos = photos;
          actualitiesData.value.push(element);
        });
        totalNewPosts.value = actualitiesData.value.filter((element) => {
          let a = moment(element?.createdAt);
          return moment().diff(a, "days") < 10;
        }).length;

        // eslint-disable-next-line prettier/prettier
        actualitiesData.value = actualitiesData.value.sort(function (a:any, b:any) {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });
        console.log("actualites", actualitiesData.value);
      })
      .catch((e) => console.log(e));
    onMounted(() => {
      setCurrentPageTitle("Actualités");
    });
    return {
      count,
      store,
      load,
      date,
      apiUrl,
      moment,
      actualitiesData,
      totalNewPosts,
      t,
    };
  },
});
